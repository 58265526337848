:root {
  box-sizing: border-box;
  --color1: #3B5284;
  --color2: #5BA8A0;
  --color3: #CBE54E;
  --color4: #94B447;
  --color5: #5D6E1E;
}

/* -------------------- GLOBAL RESET -------------------- */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

/* ----------------------- PAGE SET ------------------------ */

.about {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

/* -------------------- BODY ------------------------------- */

.body {
  padding: 1%;
  padding-bottom: 4%;
}

.body h1{
  text-align: center;
  font-size: 3.5vw;
  color: var(--color1);
}

.body-mission h2 {
  font-size: 2vw;
  color: var(--color2);
  padding-bottom: 1%;
}

.body-mission p {
  font-size: 1vw;
}

.body-bio {
  margin-top: 1%;
}

.body-bio h2 {
  font-size: 2vw;
  color: var(--color2);
}

.body-founders {
  display: flex;
  flex-direction: row;
  text-align: center;
  align-items: center;
}

@media (max-width: 750px){
  .body h1 {
    font-size: 12vw;
  }

  .body-mission h2 {
    font-size: 9vw;
    text-align: center;
  }

  .body-mission p {
    font-size: 4vw;
    padding-left: 4%;
    padding-right: 4%;
  }

  .body-bio h2 {
    font-size: 9vw;
    text-align: center;
  }

  .body-founders{
    flex-direction: column;
  }
}

/* ------------------------ BIO ------------------------------ */

.bio {
  padding: 1%;
  width: 25vw;
}

.bio h3 {
  font-size: 2vw;
}

.bio p {
  font-size: 1vw;
}

@media (max-width: 750px){
  .bio {
    padding: 0vw;
    width: 100vw;
  }
  .bio h3 {
    font-size: 7vw;
  }
  .bio p {
    font-size: 4vw;
  }
}