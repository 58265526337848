/* ---------------------- HEADER ------------------------- */

.header {
  background-color: var(--color3);
  padding-bottom: 1%;
  display: flex;
  align-items: center;
}

.header h1 {
  flex: 10;
  font-size: 4vw;
  text-align: left;
  padding-left: 1%;
}

.header-home {
  text-decoration: none;
  color: var(--color5);
}

.header-signin {
  text-decoration: none;
  flex: 1;
  text-align: center;
  font-size: 2vw;
  display: inline-block;
  margin-top: 1%;
  margin-right: 1%;
  padding-bottom: 0.5%;
  color: var(--color1);
}

.header-signup {
  text-decoration: none;
  flex: 1;
  text-align: center;
  font-size: 2vw;
  background-color: var(--color1);
  display: inline-block;
  margin-top: 1%;
  margin-right: 1%;
  padding-bottom: 0.5%;
  border-radius: 9999px;
  color: white;
}

.header-signup:hover {
  background-color: var(--color2);
}

@media (max-width: 750px) {

  .header {
    padding-bottom: 2%;
  }

  .header h1 {
    flex: 3;
    font-size: 10vw;
  }

  .header-signin{
    font-size: 5vw;
  }

  .header-signup{
    font-size: 5vw;
  }
}

/* ------------------------------- FOOTER --------------------- */

.footer-all {
  background-color: navajowhite;
  padding: 1%;
  font-size: 1vw;
  margin-top: auto;
}

.footer {
  display: flex;
  flex-direction: row;
}

.footer a{
  text-decoration: none;
  color: black;
}

.footer a:hover{
  color: white;
}

.footer-menu {
  flex: 6;
  display: flex;
  flex-direction: column;
}

.footer-social {
  display: flex;
  flex-direction: column
}

.footer-copyright {
  text-align: center;
}

@media (max-width: 750px){
  .footer{
    flex-direction: column;
    text-align: center;
    font-size: 3vw;
  }
  .footer-copyright {
    font-size: 2vw;
  }
}