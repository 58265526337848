:root {
  box-sizing: border-box;
  --color1: #3B5284;
  --color2: #5BA8A0;
  --color3: #CBE54E;
  --color4: #94B447;
  --color5: #5D6E1E;
}

/* -------------------- GLOBAL RESET -------------------- */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

/* --------------------- PAGE SET ------------------------- */

.homepage {
  background-color: whitesmoke;
}

/* --------------------------- MAIN ------------------------- */

.homepage-main {
  min-height: 100vh;
  background-color: var(--color3);
}

.authspace{
  display: flex;
  padding: 0.5%;
}

.spacer{
  flex: 5;
}

.auth {
  flex: 1;
  padding: 0% 1% 0% 1%;
  display: inline-flex;
}

.homepage-signin {
  text-decoration: none;
  flex: 1;
  text-align: center;
  font-size: 2vw;
  display: inline-block;
  margin-top: 1%;
  margin-right: 1%;
  padding-bottom: 0.5%;
  color: var(--color1);
}

.homepage-signup {
  text-decoration: none;
  flex: 1;
  text-align: center;
  font-size: 2vw;
  background-color: var(--color1);
  display: inline-block;
  margin-top: 1%;
  margin-right: 1%;
  padding: 0% 1% 2% 1%;
  border-radius: 9999px;
  color: white;
}

.homepage-signup:hover {
  background-color: var(--color2);
}

.homepage-hero {
  text-align: center;
  margin-top: 25vh;
}

.homepage-hero h1 {
  font-size: 8vw;
  color: floralwhite;
}

.homepage-hero h2 {
  font-size: 3vw;
  color: slategrey;
}