:root {
  box-sizing: border-box;
  --color1: #3B5284;
  --color2: #5BA8A0;
  --color3: #CBE54E;
  --color4: #94B447;
  --color5: #5D6E1E;
}

/* -------------------- GLOBAL RESET --------------------*/

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

/* ------------------------- HERO ----------------------------- */

.hero {
  background-color: var(--color3);
  align-items: center;
  text-align: center;
  min-height: 100vh;
}

.hero a{
  text-decoration: none;
  color: var(--color5);
}

.hero-title h1 {
  font-size: 5vw;
  color: var(--color5);
}

.hero-base {
  margin-top: 25vh;
}

.hero-base h2 {
  font-size: 7vw;
  color: var(--color2);
}

.hero-base p {
  font-size: 2.2vw;
  color: whitesmoke;
  padding-right: 20%;
  padding-left: 20%;
}

.hero-signup {
  padding-top: 3%;
  padding-bottom: 3%;
}

.hero-signup a {
  font-size: 3vw;
  background: var(--color1);
  display: inline-block;
  padding: 1%;
  padding-bottom: 1.2%;
  border-radius: 9999px;
  text-decoration: none;
  color: white;
}

.hero-signup a:hover {
  background-color: var(--color2);
}

@media (max-width: 750px){

  .hero-title h1 {
    font-size: 14vw;
  }

  .hero-base {
    margin-top: 20vw;
  }

  .hero-base h2 {
    font-size: 12vw;
  }
  
  .hero-base p {
    font-size: 4.3vw;
    padding-right: 5%;
    padding-left: 5%;
  }

  .hero-signup a {
    font-size: 7vw;
    margin-top: 10%;
    padding: 2%;
    padding-bottom: 2.5%;
    padding-top: 1.5%;
  }

}

/* ------------------------------- MOTTO ------------------------------ */

.motto {
  background: var(--color2);
  display: flex;
  flex-direction: row;
  text-align: center;
  color: antiquewhite;
}

.motto h3 {
  font-size: 2.3vw;
  color: var(--color3);
}

.motto p {
  font-size: 1.5vw;
  text-align: left;
  padding-top: 0.2vw;
  padding-bottom: 0.2vw;
}

.motto-item1 {
  flex: 2;
  padding: 1vw;
}

.motto-item2 {
  flex: 3;
  padding: 1vw;
}

.motto-item2-container {
  display: flex;
  flex-direction: row;
}

.motto-item2-item1 {
  padding-right: 0.5vw;
}

.motto-item2-item2 {
  padding-left: 0.5vw;
}

@media (max-width: 750px) {
  .motto {
    flex-direction: column;
    padding-top: 2%;
    padding-bottom: 4%;
  }

  .motto h3 {
    font-size: 7vw;
  }

  .motto P {
    font-size: 5vw;
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 3%;
  }

  .motto-item2-container{
    flex-direction: column;
  }
  .motto-item2-item1 {
    padding-right: 0vw;
  }
  .motto-item2-item2 {
    padding-left: 0vw;
  }
}