:root {
  box-sizing: border-box;
  --color1: #3B5284;
  --color2: #5BA8A0;
  --color3: #CBE54E;
  --color4: #94B447;
  --color5: #5D6E1E;
}

/* -------------------- GLOBAL RESET -------------------- */

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

/* ----------------------- PAGE SET ------------------------ */

.faq {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: whitesmoke;
}

/* ------------------------- BODY -------------------------------- */

.faq-body {
  padding: 1%;
  padding-bottom: 4%;
}

.faq-body h2 {
  text-align: center;
  font-size: 3.5vw;
  color: var(--color1);
}

.faq-section{
  padding-left: 2%;
}

.faq-section h3 {
  font-size: 2.5vw;
  color: var(--color2);
}

.faq-section p {
  font-size: 2vw;
  padding-left: 3%;
}

@media (max-width: 750px){
  .faq h2{
    font-size: 12vw;
  }
}